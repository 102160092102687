import { create } from 'zustand'

export type ShopifyDataStore = {
  loaded: boolean
  client: any
  ui: any
}

export const useShopify = create<ShopifyDataStore>(() => ({
  loaded: false,
  client: null,
  ui: null,
}))

const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
export function loadShopifyScript() {
  const script = document.createElement('script')
  script.async = true
  script.src = scriptURL;
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script)
  script.onload = () => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const client = ShopifyBuy.buildClient({
      domain: 'b51eaf-14.myshopify.com',
      storefrontAccessToken: '0c9030203181f3126c375a3de63b7844',
    })
    // @ts-ignore
    // eslint-disable-next-line no-undef
    ShopifyBuy.UI.onReady(client).then((ui) => {
      useShopify.setState({
        loaded: true,
        // @ts-ignore
        // eslint-disable-next-line no-undef
        client,
        ui,
      })
    })
  }
}
