import {
  Center, Image, Progress, Text, VStack,
} from '@chakra-ui/react'
import { useLoading } from '../../store'

export const LoadingScreen = () => {
  const loaded = useLoading((s) => s.loaded)
  const progress = useLoading((s) => s.progress)
  return (
    <Center
      style={{
        opacity: loaded ? 0 : 1,
        display: 'flex',
        background: `rgba(10,10,10,${loaded ? 0 : 1})`,
        transition: 'opacity 1000ms ease 750ms, background 1000ms ease 500ms',
        position: 'absolute',
        top: 0,
        pointerEvents: 'none',
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <VStack w='100%' maxW='300px'>
        <Image src='/assets/images/iso-logo-white.png' />
        <Text fontSize='4xl' mixBlendMode='difference' fontFamily='courier' color='white'>
          {(progress * 100).toFixed(1)}%
        </Text>
        <Progress
          sx={{
            justifyContent: 'center',
            '& > div:first-child': {
              transitionProperty: 'width',
            },
          }}
          bg='transparent'
          display='flex'
          isAnimated
          h='20px'
          value={progress * 100}
          w='100%'
        />
      </VStack>
    </Center>
  )
}
