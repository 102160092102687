import { ChakraProvider } from '@chakra-ui/react'
import { sceneViews } from '@iso-3d/shared'
import { useIsomorphicLayoutEffect } from '@react-spring/three'
import { useDetectGPU } from '@react-three/drei'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { initialize } from '../../backend/app'
import { setView } from '../../store'
import { loadShopifyScript } from '../../store/shopify'
import './App.css'
import { Flat } from './Flat'
import { LoadingScreen } from './LoadingScreen'
import { Scene } from './Scene/Scene'
import { theme } from './theme'

loadShopifyScript()
initialize()

const Background = () => (
  <div
    style={{
      position: 'fixed',
      backgroundColor: '#111',
      width: '100%',
      height: '100%',
      zIndex: -1,
    }}
  />
)

const App = () => {
  const { pathname } = useLocation()

  const { tier } = useDetectGPU()
  useIsomorphicLayoutEffect(() => {
    const match = [...sceneViews].find(({ path }) => path === pathname)
    if (match) setView(match.id)
  }, [pathname])
  return (
    <>
      <Background />
      <Scene />
      <Flat gpuTier={tier} />
      <LoadingScreen />
    </>
  )
}

const Router = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
)

export default Router
