import { getInitViewPosition, viewPositions } from 'consts/positions'
import { useApp } from 'store'
import { Vector3 } from 'three'
import { Layer } from '../../../../SceneViewer/types'
import { Background } from './Background'
import { ProductScroll } from './Products/ProductScroll'

export const DemoScene = () => (
    <group>
      <ambientLight intensity={0.05}/>
      <Background/>
      <ProductScroll/>
    </group>
)

const initCameraPos = viewPositions[useApp.getState().view]
export const initCamera = {
  position: new Vector3(...getInitViewPosition(useApp.getState().view)),
  target: new Vector3(...initCameraPos.lookAt),
}
export const demoLayer: Layer = {
  Component: DemoScene,
  background: 0x000000,
  camera: initCamera,
}
