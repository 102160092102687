import { magnifyingGlassGlbPath } from '@iso-3d/shared'
import {
  animated as a,
  config,
  easings, useSpring,
} from '@react-spring/three'
import { useGLTF } from '@react-three/drei'
import { useEffect, useMemo, useState } from 'react'
import {
  Mesh, MeshPhongMaterial,
} from 'three'
import { GLTF } from 'three-stdlib'
import { useSelectedProduct } from '../../../../../../store/products'
import { ProductViewButtons } from './ProductViewButtons'
import './styles.css'

export const MagnifyingGlassView = () => {
  const selectedProduct = useSelectedProduct()

  const {
    nodes,
  } = useGLTF(magnifyingGlassGlbPath) as unknown as GLTF & { nodes: Record<string, Mesh> }

  const meshes = useMemo(() => Object.values(nodes).filter((n) => n.isMesh), [nodes])

  useEffect(() => {
    meshes.forEach((m) => {
      if (m.name === 'glass') {
        const material = m.material as MeshPhongMaterial
        material.opacity = 0.15
        material.depthWrite = false
      }
    })
  }, [meshes])
  const [material] = useState(new MeshPhongMaterial({ color: 0xcdcdcd, transparent: true }))
  useSpring<{opacity: number}>({
    opacity: selectedProduct ? 1 : 0,
    config: {
      duration: 500,
      easing: selectedProduct ? easings.easeOutCubic : easings.easeInCubic,
    },
    onChange: ({ value: { opacity: val } }) => {
      material.opacity = val
    },
  })

  // const outerPos = useMemo(() => [0, 0, -2 + w * 2], [w])
  // const innerPos = useMemo<Vector3Tuple>(() => [0, 0, -w * 2], [w])
  const { z } = useSpring({
    z: selectedProduct ? -1 : -10,
    config: config.slow,
    delay: selectedProduct ? 0 : 333,
  })
  return (
    <a.group
    // rotation={rotationY.to((ry) => [0, ry, 0])}
    // @ts-ignore
    position={z.to((v) => [0, 0, v])}
    >
      <group>
        {/* <mesh
          rotation={[0, Math.PI / 2, 0]}
          geometry={Cube.geometry}
          material={material}
          scale={[0.2, 1.4, 1.2]}
        /> */}
      <group position={[-2.8, -4.85, 0.9]} rotation={[0, 0, -Math.PI / 6]} scale={[0.037, 0.037, 0.037]}>

        {
          meshes.map((mesh) => (
            <mesh
              material={mesh.material}
              position={mesh.position}
              rotation={mesh.rotation}
              scale={mesh.scale}
              geometry={mesh.geometry}
              key={mesh.uuid}

            />
          ))
        }
        </group>
        <ProductViewButtons/>
      </group>
    </a.group>
  )
}
