import { Product, getProductAssetPaths, products } from '@iso-3d/shared'
import { a, useSpring } from '@react-spring/three'
import { useGLTF, useTexture } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import {
  Group, Sprite, SpriteMaterial, Texture, Vector3, Vector3Tuple, sRGBEncoding,
} from 'three'
import { lerp } from 'three/src/math/MathUtils'
import { usePointerHover } from '../../../../../../hooks/usePointerHover'
import { selectProduct, useProducts } from '../../../../../../store/products'
import { getScroll } from '../../../../../../store/scroll'
import { ManualClickableSphere } from '../../../../../shared/ClickableSphere'
import { FloatingContainer } from '../../../../../shared/FloatingContainer'
import { MagnifyingGlassView } from './MagnifyingGlassView'
import { MeshesProductView } from './MeshesProductView'
import { SceneProductView } from './SceneProductView'
import { GLTF } from './types'

function mod(n: number, m: number) {
  return ((n % m) + m) % m
}

// const offsetXIndex: Record<number, number> = {}

// const getOffsetXIndex = (index: number) => {
//   if (offsetXIndex[index] === undefined) {
//     offsetXIndex[index] = Math.random() - 0.5
//   }
//   return offsetXIndex[index]
// }

const productSpacing = 2.5
const offset = (productSpacing * products.length) / 2.64
const getProductPosition = (
  viewportWidth: number,
  index: number,
  numProducts: number,
  scroll: number,
): Vector3Tuple => [
  0, // 0.3 * Math.sin((index / numProducts) * Math.PI * 2) + getOffsetXIndex(index) * viewportWidth * 0.2,
  mod(-index * productSpacing + scroll / numProducts + numProducts * offset, numProducts * productSpacing)
    - offset,
  -2,
]

// const getInnerScale = (y: number): number => 1
const getInnerScale = (y: number): number => 1.2 * Math.sin((y / 2 + Math.PI / 2)) ** 0.5
const selectedPosition = new Vector3(0, 0, -2)

const onSpriteTextureLoad = (textures: Array<Texture> | Texture) => {
  const ts = Array.isArray(textures) ? textures : [textures]
  ts.forEach((texture) => {
    const t = texture
    t.encoding = sRGBEncoding
  })
}

const ProductView = ({ product, index, numProducts }: { product: Product; index: number; numProducts: number }) => {
  const { gltf: gltfPath, sprite } = useMemo(() => getProductAssetPaths(product), [product])
  const gltf = useGLTF(gltfPath) as unknown as GLTF
  const spriteTexture = useTexture(sprite, onSpriteTextureLoad)
  const [spriteMaterial] = useState(new SpriteMaterial({ map: spriteTexture }))

  // const normalMapUrl = useMemo(() => getNormalMapUrl(product), [product])
  // const normalMap = useTexture(normalMapUrl, onNormalLoad)

  const { width: w } = useThree((state) => state.viewport)

  const selectedProductId = useProducts((state) => state.selectedProductId)

  const initPosition = useRef(getProductPosition(w, index, numProducts, getScroll()))
  const initInnerScale = useRef(getInnerScale(initPosition.current[1]))

  const groupRef = useRef<Group>(null)
  const modelGroupRef = useRef<Group>(null)
  const spriteRef = useRef<Sprite>(null)

  const [hovered, setHovered] = useState(false)

  const onPointerOver = useCallback(() => {
    if (selectedProductId) return
    setHovered(true)
  }, [selectedProductId])
  const onPointerOut = useCallback(() => {
    if (selectedProductId) return
    setHovered(false)
  }, [selectedProductId])
  const hoverProps = usePointerHover(onPointerOver, onPointerOut)

  const outerScale = useMemo(() => (hovered && !selectedProductId ? 1.2 : 1), [hovered, selectedProductId])
  const { selectedScale } = useSpring({
    selectedScale: selectedProductId === product.id ? 1.2 : 0,
    delay: selectedProductId ? 0 : 500,
  })

  const { scale } = useSpring({
    scale: outerScale,
  })

  const { spriteScale } = useSpring({
    spriteScale: selectedProductId ? 0 : 1.75,
    delay: selectedProductId ? 0 : 750,
  })

  const posHelper = useRef(new Vector3())
  const selectedPosHelper = useRef(new Vector3())
  const selectedRotationHelper = useRef(new Vector3())
  const { selectTween } = useSpring<{ selectTween: number }>({
    selectTween: selectedProductId ? 1 : 0,
    delay: selectedProductId ? 0 : 333,
    config: { duration: 600 },
  })

  const { rotationSelectTween } = useSpring<{ rotationSelectTween: number }>({
    rotationSelectTween: selectedProductId ? 1 : 0,
    config: { duration: 1000 },
    onRest: ({ value: { rotationSelectTween: val } }) => {
      if (val === 0) {
        selectedRotationHelper.current.y = 0
      }
    },
  })

  // if pointer is down, set pointerDown ref to true
  // if pointer is up, set pointerDown ref to false
  // on unmount, set pointerDown ref to false and remove listener
  const pointerDown = useRef(false)
  const onPointerDown = useCallback(() => {
    pointerDown.current = true
  }, [])

  useEffect(() => {
    const onPointerUp = () => {
      pointerDown.current = false
    }
    window.addEventListener('pointerup', onPointerUp)
    return () => {
      pointerDown.current = false
      window.removeEventListener('pointerup', onPointerUp)
    }
  }, [])

  useFrame(() => {
    if (groupRef.current && modelGroupRef.current && spriteRef.current) {
      const [x, y, z] = getProductPosition(w, index, numProducts, getScroll())
      posHelper.current.set(x, y, z)
      if (selectedProductId) {
        if (selectedProductId === product.id) {
          if (!pointerDown.current) {
            selectedRotationHelper.current.y = selectedRotationHelper.current.y >= Math.PI
              ? -Math.PI + 0.005 : selectedRotationHelper.current.y + 0.005
          }
          selectedPosHelper.current.set(selectedPosition.x, selectedPosition.y, selectedPosition.z)
        } else {
          selectedPosHelper.current.set(x, y, z)
        }
      }
      const selectTweenVal = selectTween.get()
      const rotationTweenVal = rotationSelectTween.get()
      groupRef.current.position.lerpVectors(posHelper.current, selectedPosHelper.current, selectTweenVal)
      groupRef.current.rotation.y = lerp(0, selectedRotationHelper.current.y, rotationTweenVal)
      const outerScaleLerp = lerp(getInnerScale(y), scale.get(), selectTweenVal)
      const innerScaleLerp = selectedScale.get()
      groupRef.current.scale.set(outerScaleLerp, outerScaleLerp, outerScaleLerp)
      modelGroupRef.current.scale.set(innerScaleLerp, innerScaleLerp, innerScaleLerp)
    }
  })
  return (
    // @ts-ignore
    <group
      onPointerDown={onPointerDown}
      position={initPosition.current}
      scale={initInnerScale.current}
      ref={groupRef}
    >
      {selectedProductId ? null : (
        <ManualClickableSphere
          onHover={() => {
            if (selectedProductId) return
            setHovered(true)
          }}
          onBlur={() => {
            if (selectedProductId) return
            setHovered(false)
          }}
          onClick={
            selectedProductId
              ? undefined
              : () => {
                selectProduct(product.id)
              }
          }
          {...hoverProps}
          scale={[1.2, 1.2, 1.2]}
        />
      )}
      <FloatingContainer>
        <group scale={[0, 0, 0]} ref={modelGroupRef}>
          {product.renderScene ? (
            <SceneProductView product={product} gltf={gltf} />
          ) : (
            <MeshesProductView gltf={gltf} product={product} />
          )}
        </group>
        <a.sprite ref={spriteRef} scale={spriteScale.to((s) => [s, s, s])} material={spriteMaterial} />
      </FloatingContainer>
    </group>
  )
}

export const ProductScroll = () => (
  <>
    <pointLight distance={7} intensity={1.5} position={[0.8, 0.15, -4]} />
    <group>
      {products.map((product, i) => (
        <ProductView key={product.id} numProducts={products.length} product={product} index={i} />
      ))}
    </group>
    <MagnifyingGlassView />
  </>
)
