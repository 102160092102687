import { Vector3, Vector3Tuple } from 'three'

const v3Helper0 = new Vector3()
const v3Helper1 = new Vector3()
export const addV3 = (v1: Vector3Tuple, v2: Vector3Tuple): Vector3Tuple => {
  v3Helper0.set(...v1)
  v3Helper1.set(...v2)
  v3Helper0.add(v3Helper1)
  return v3Helper0.toArray()
}
