import { SceneView, sceneViews, ViewId } from '@iso-3d/shared'
import { create } from 'zustand'

// transition stages
// 0 - moving to doorway
// 1 - moving between doorways
// 2 - moving to destination
export type AppState = {
  entered: boolean
  view: ViewId
  transition: { to: ViewId; from: ViewId, stage: 0 | 1 | 2 } | null
  menuOpen: boolean
}

const getInitialView = (views: SceneView[]): ViewId => {
  if (typeof window === 'undefined') return 'demo'
  const { pathname } = window.location
  const match = views.find((view) => view.path === pathname)
  return match ? match.id : 'demo'
}

export const useApp = create<AppState>(() => ({
  entered: false,
  view: getInitialView(sceneViews),
  transition: null,
  menuOpen: false,
}))

export const setView = (viewName: ViewId) => {
  const currView = useApp.getState().view
  useApp.setState({ view: viewName, transition: { stage: 0, from: currView, to: viewName } })
}
