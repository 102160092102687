import { Controller } from '@react-spring/core'
import { useApp } from './app'
import { useProducts } from './products'

let scrollTarget = 0
let scroll = 0
const scrollSubscribers: Record<string, (updated: number) => void> = {}

const getUniqueSubscriberKey = () => {
  let key = Math.random().toString(36).substring(7)
  while (scrollSubscribers[key]) {
    key = Math.random().toString(36).substring(7)
  }
  return key
}

export type Unsubscribe = () => void

export const unsubscribeFromScroll = (key: string) => {
  delete scrollSubscribers[key]
}

export const subscribeToScroll = (callback: (updated: number) => void): Unsubscribe => {
  const key = getUniqueSubscriberKey()
  scrollSubscribers[key] = callback
  return () => {
    unsubscribeFromScroll(key)
  }
}

const scrollAnimator = new Controller<{scroll: number}>({
  scroll: 0,
  onChange: ({ value: { scroll: newScroll } }) => {
    scroll = newScroll
    Object.values(scrollSubscribers).forEach((subscriber) => subscriber(newScroll))
  },
})

const getShouldScroll = () => !useProducts.getState().selectedProductId && !useApp.getState().menuOpen

const handleScroll = (event: WheelEvent) => {
  // scrollTarget = Math.max(0, Math.min(scroll + event.deltaY / 10, MAX_SCROLL))
  if (!getShouldScroll()) return
  scrollTarget = scroll + event.deltaY / 10
  scrollAnimator.start({ scroll: scrollTarget })
}

window.addEventListener('wheel', handleScroll)

let touchStart = 0

const handleTouchStart = (event: TouchEvent) => {
  touchStart = event.touches[0].clientY
}

const handleTouchMove = (event: TouchEvent) => {
  if (!getShouldScroll()) return
  const touchEnd = event.touches[0].clientY
  scrollTarget = scroll + (touchStart - touchEnd) / 35
  scrollAnimator.start({ scroll: scrollTarget })
}

window.addEventListener('touchstart', handleTouchStart)
window.addEventListener('touchmove', handleTouchMove)

export const getScroll = () => scroll
