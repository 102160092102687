import { shaderMaterial } from '@react-three/drei'
import { Vector2, Vector3 } from 'three'
import { simplexNoise4 } from './simplexNoise4'

const vertexShader = `
varying vec3 vPosition;
varying vec2 vUv;

void main() {
    vPosition = position;
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
}
`

// Fragment Shader
const fragmentShader = `
varying vec3 vPosition;
varying vec2 vUv;
uniform float time;
uniform vec3 color;
uniform float opacity;

${simplexNoise4}

float fbm(vec3 p)
{
  float fbmTime = time * 0.3;
    float f;
    f  = 0.50000*snoise4(vec4(p, fbmTime) ); p = p*2.01;
    f += 0.25000*snoise4(vec4( p, fbmTime) ); p = p*2.02; //from iq
    f += 0.12500*snoise4(vec4( p, fbmTime) ); p = p*2.03;
    f += 0.06250*snoise4(vec4( p, fbmTime) ); p = p*2.04;
    f += 0.03125*snoise4(vec4( p, fbmTime) );
    // f += 0.015625 * snoise4(vec4(p, fbmTime)); p = p * 2.5; // Additional octave
    // f += 0.0078125 * snoise4(vec4(p, fbmTime)); // Additional octave
    return f;
}

void main() {
    vec3 position = vPosition;
    vec3 noisePosition = position*20.0;
    // float noiseValue = 0.5 + 0.5* fbm(noisePosition + vec3(0.0, 0.0, time*0.5));
    float noiseValue = 0.7 + 0.3*(snoise4(vec4(noisePosition, time))*0.4 - snoise4(vec4(noisePosition*2.01, time)));
    float diagonalPhase = 1. + sin(90.0*(vUv.x + vUv.y) + time*0.5) * 0.0025;
    float diagonal = 45.0*(vUv.x - vUv.y);
    float diagonalLine = 0.8 - fract(diagonal)*0.4*max(0.0, pow(vUv.y, 1.5) - pow(vUv.x, 1.5));
    gl_FragColor = vec4(color,diagonalLine * noiseValue); //* vec4(color, noiseValue * opacity);
}
`

export const NoiseMaterial1 = shaderMaterial(
  {
    time: 0, resolution: new Vector2(0, 0), opacity: 1, color: new Vector3(0, 0, 0),
  },
  vertexShader,
  fragmentShader,
  (material) => {
    const mat = material
    if (mat) mat.transparent = true
  },
)
