import {
  closeButtonGlbPath,
  galleryGlbPath,
  getProductAssetPaths,
  infoGlbPath,
  magnifyingGlassGlbPath,
  products,
  shoppingBagGlbPath,
} from '@iso-3d/shared'
import { useGLTF, useProgress, useTexture } from '@react-three/drei'
import { create } from 'zustand'

products.forEach((product) => {
  const {
    gltf,
    texture,
    sprite, // bump, roughness, normal,
  } = getProductAssetPaths(product)
  useTexture.preload(sprite)
  useGLTF.preload(gltf)
  if (!product.renderScene) {
    useTexture.preload(texture)
    // useTexture.preload(bump)
    // useTexture.preload(roughness)
    // useTexture.preload(normal)
  }
})

// useGLTF.preload(productFrameGlbPath)
useGLTF.preload(magnifyingGlassGlbPath)
useGLTF.preload(closeButtonGlbPath)
useGLTF.preload(infoGlbPath)
useGLTF.preload(galleryGlbPath)
useGLTF.preload(shoppingBagGlbPath)

const EXPECTED_ITEMS = 39

if (EXPECTED_ITEMS) {
  useProgress.subscribe(({ loaded }) => {
    setTimeout(() => {
      useLoading.setState({ progress: Math.min(1, loaded / EXPECTED_ITEMS), loaded: loaded >= EXPECTED_ITEMS })
    }, 500)
  })
}
export const useLoading = create(() => ({
  loaded: EXPECTED_ITEMS < 1,
  progress: EXPECTED_ITEMS ? 0 : 1,
}))
