import {
  Center, Flex, HStack, IconButton, Image,
} from '@chakra-ui/react'
import {
  CSSProperties, useCallback, useEffect, useMemo,
} from 'react'
import { useGallery, useProducts, useSelectedProduct } from '../../../store/products'
import { useProductUrls } from './hooks'
import './styles.css'

const arrowButtonSrc = '/assets/images/arrow.png'
const xSrc = '/assets/images/x.png'

export const ProductGallery = () => {
  const { isOpen, selectedImageIndex } = useGallery()
  const selectedProduct = useSelectedProduct()

  const onGoTo = useCallback((index: number) => {
    useGallery.setState({ selectedImageIndex: index })
  }, [])

  const onNext = useCallback(() => {
    if (!selectedProduct) return
    useGallery.setState({ selectedImageIndex: (selectedImageIndex + 1) % selectedProduct.numImages })
  }, [selectedProduct, selectedImageIndex])

  const onPrevious = useCallback(() => {
    if (!selectedProduct) return
    useGallery.setState({
      selectedImageIndex: (selectedImageIndex - 1 + selectedProduct.numImages) % selectedProduct.numImages,
    })
  }, [selectedProduct, selectedImageIndex])

  useEffect(
    () => useProducts.subscribe(({ selectedProductId }) => {
      if (!selectedProductId) {
        useGallery.setState({ isOpen: false, selectedImageIndex: 0 })
      }
    }),
    [],
  )

  const style = useMemo<CSSProperties>(
    () => (isOpen ? { opacity: 1, pointerEvents: 'auto' } : { opacity: 0, pointerEvents: 'none' }),
    [isOpen],
  )

  const productUrls = useProductUrls(selectedProduct)
  return (
    <div style={style} className='overlay'>
      <Center
        opacity={isOpen ? 1 : 0}
        transition={`opacity 300ms ease ${isOpen ? 300 : 0}ms`}
        maxW='600px'
        w='90%'
        h='80%'
        gap={2}
        flexFlow='column'
      >
        <Flex align='center' w='100%'>
          <IconButton
            aria-label='close'
            onClick={() => useGallery.setState({ isOpen: false })}
            icon={
              <Flex gap={1} align='center'>
                <Image position='relative' top='2px' filter='invert(100%)' opacity={0.7} src={xSrc} h={8} />
              </Flex>
            }
          />
          <Flex gap={2} align='center' ml='auto'>
            <IconButton
              aria-label='previous'
              onClick={onPrevious}
              opacity={0.7}
              variant='unstyled'
              ml={4}
              _hover={{
                opacity: 1,
              }}
              icon={
                <Image
                  h={10}
                  transform='scale(-1, 1)'
                  filter='invert(100%) drop-shadow(1px 1px 2px #00000077)'
                  src={arrowButtonSrc}
                />
              }
            />

            <IconButton
              aria-label='next'
              onClick={onNext}
              opacity={0.7}
              variant='unstyled'
              mr={4}
              _hover={{
                opacity: 1,
              }}
              icon={<Image h={10} filter='invert(100%) drop-shadow(1px 1px 2px #00000077)' src={arrowButtonSrc} />}
            />
          </Flex>
        </Flex>
        <Center bg='white' overflow='hidden' borderRadius={6} h='auto' minH='0' pos='relative' w='auto'>
          <Image src={productUrls[selectedImageIndex]} h='100%' objectFit='contain' />
          <Flex align='center' onClick={onPrevious} position='absolute' left={0} top={0} w='50%' h='100%'></Flex>
          <Flex
            align='center'
            onClick={onNext}
            position='absolute'
            justify='flex-end'
            right={0}
            top={0}
            w='50%'
            h='100%'
          ></Flex>
        </Center>
        <HStack borderRadius={4} maxW='100%' p={2}>
          <Flex w='100%' py={2} px={3} overflowX='auto' border='1px solid #111' borderRadius={4} bg='#222224'>
            <Flex flex={1} />
            <Flex gap={2} h='auto'>
              {productUrls.map((url, i) => (
                <IconButton
                  key={url}
                  variant='unstyled'
                  borderRadius={6}
                  boxShadow={`0px 0px 5px ${i === selectedImageIndex ? '#fff' : '#000'}`}
                  overflow='hidden'
                  h='auto'
                  w='auto'
                  position='relative'
                  bg='transparent'
                  aria-label={`image ${i + 1}`}
                  icon={<Image src={url} h='90px' objectFit='contain' />}
                  onClick={() => onGoTo(i)}
                />
              ))}
            </Flex>
            <Flex flex={1} />
          </Flex>
        </HStack>
      </Center>
    </div>
  )
}
