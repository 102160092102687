import { ThreeEvent, useThree } from '@react-three/fiber'
import { useCallback } from 'react'

export const usePointerHover = (onOver?: () => void, onOut?: () => void) => {
  const { domElement } = useThree((s) => s.gl)
  const onPointerOver = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation()
    if (onOver) onOver()
    domElement.style.cursor = 'pointer'
  }, [domElement, onOver])

  const onPointerOut = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation()
    if (onOut) onOut()
    domElement.style.cursor = 'auto'
  }, [domElement, onOut])

  return { onPointerOver, onPointerOut }
}
