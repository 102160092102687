import { Product, getProductAssetPaths } from '@iso-3d/shared'
import { useTexture } from '@react-three/drei'
import { useMemo } from 'react'
import {
  DoubleSide, Mesh,
  Texture, sRGBEncoding,
} from 'three'
import { GLTF } from './types'

const onTextureLoad = (textures: Array<Texture> | Texture) => {
  const ts = Array.isArray(textures) ? textures : [textures]
  ts.forEach((texture) => {
    const t = texture
    t.flipY = false
    t.encoding = sRGBEncoding
  })
}

export const MeshesProductView = ({ gltf: { nodes }, product }: { gltf: GLTF; product: Product }) => {
  const {
    texture: textureUrl,
  } = useMemo(() => getProductAssetPaths(product), [product])
  const texture = useTexture(textureUrl, onTextureLoad)
  // const bumpMap = useTexture(bump, onTextureLoad)
  // const normalMap = useTexture(normal, onTextureLoad)
  // const roughnessMap = useTexture(roughness, onTextureLoad)
  const meshes = useMemo(() => {
    const m: Array<Mesh> = []
    for (let i = 0; i < Object.keys(nodes).length; i += 1) {
      const key = Object.keys(nodes)[i]
      const node = nodes[key]
      if (node.type === 'Mesh') {
        node.name = `${product.id}-${key}`
        m.push(node as Mesh)
      }
    }
    return m
  }, [product, nodes])
  return (
    <group>
      {meshes.map((mesh) => (
        <mesh key={mesh.name} geometry={mesh.geometry}>
          <meshStandardMaterial
            side={DoubleSide}
            roughness={0.985}
            metalness={0.05}
            map={texture}
          />
        </mesh>
      ))}
    </group>
  )
}
