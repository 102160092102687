import { products } from '@iso-3d/shared'
import { create } from 'zustand'

export type ProductsStore = {
  selectedProductId: string | null
  selectedVariantId: string | null
}

export type GalleryStore = {
  isOpen: boolean
  selectedImageIndex: number
}
export type DescriptionStore = {
  isOpen: boolean
}

export const useProducts = create<ProductsStore>(() => ({
  selectedProductId: null,
  selectedVariantId: null,
}))

export const getProduct = (id: string) => products.find((p) => p.id === id) || null

export const useSelectedProduct = () => {
  const selectedProductId = useProducts((state) => state.selectedProductId)
  return selectedProductId ? getProduct(selectedProductId) : null
}

export const useGallery = create<GalleryStore>(() => ({
  isOpen: false,
  selectedImageIndex: 0,
}))

export const useDescription = create<DescriptionStore>(() => ({
  isOpen: false,
}))

export const selectProduct = (id: string) => {
  useProducts.setState({
    selectedProductId: id,
    selectedVariantId: products.find((p) => p.id === id)?.variants?.[0]?.shopifyId || null,
  })
}

export const deselectProduct = () => {
  useProducts.setState({ selectedProductId: null, selectedVariantId: null })
}
