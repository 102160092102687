import { Box } from '@chakra-ui/react'
import { Icosahedron, Sphere } from '@react-three/drei'
import { Canvas, useThree } from '@react-three/fiber'
import { CSSProperties, Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLoading } from 'store'
import { ACESFilmicToneMapping } from 'three'
import { ConfigureGL } from '../../SceneViewer/ConfigureGL'
import { DemoScene, initCamera } from './layers/DemoLayer'

// const layers = [demoLayer]

// const sharedPostprocessing = {
//   effects: [
//     new BloomEffect({
//       luminanceThreshold: 0.55,
//       intensity: 2,
//       luminanceSmoothing: 0.2,
//     }),
//   ],
// }

const glProps = {
  powerPreference: 'high-performance',
  antialias: false,
  alpha: true,
  toneMapping: ACESFilmicToneMapping,
  toneMappingExposure: 0.5,
}
const style: CSSProperties = {
  userSelect: 'none',
  touchAction: 'none',
  pointerEvents: 'auto',
}

const CameraController = () => {
  const camera = useThree((s) => s.camera)

  useEffect(() => {
    camera.position.set(...initCamera.position.toArray())
    camera.lookAt(...initCamera.target.toArray())
  }, [])

  return null
}

export const Scene = () => {
  const loaded = useLoading((s) => s.loaded)
  return (
    <Box w='100%' h='100%' opacity={loaded ? 1 : 0} transition='opacity 500ms'>
      <Canvas style={style} frameloop='always' gl={glProps}>
        <color attach='background' args={['#111']} />
        <Suspense fallback={<Sphere />}>
          <ErrorBoundary fallback={<Icosahedron />} >
            <DemoScene />
            <ConfigureGL />
            <CameraController />
          </ErrorBoundary>
        </Suspense>
      </Canvas>
    </Box>
  )
}
