import {
  Center, Collapse, Flex,
} from '@chakra-ui/react'
import { Product } from '@iso-3d/shared'
import { useRef } from 'react'
import { useMounted } from '../../../hooks/useMounted'
import { useShopifyBuyButton } from './hooks'

export const BuyButton = ({
  product,
  variantId,
  selected,
}: {
  product: Product
  variantId: string | null
  selected: boolean
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const mounted = useMounted()
  const loading = useShopifyBuyButton(product.shopifyId, variantId, selected && mounted, divRef)
  // const elementId = useMemo(() => getElementId(product.shopifyId, variantId), [product, variantId])
  return (
    <Flex flexFlow='column' w='100%'>
      <Collapse in={mounted && selected && !loading} style={{ width: '100%' }}>
        <Center height='70px' py={1} w='100%'>
          <div ref={divRef} />
        </Center>
      </Collapse>
    </Flex>
  )
}
