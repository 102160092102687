import { Product } from '@iso-3d/shared'
import { useFrame } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import {
  AnimationMixer, Mesh, MeshStandardMaterial, Vector3Tuple, sRGBEncoding,
} from 'three'
import { GLTF } from './types'

const defaultScale: Vector3Tuple = [0.9, 0.9, 0.9]
export const SceneProductView = ({
  gltf: { scene, animations, nodes },
  product: { model },
}: {
  gltf: GLTF
  product: Product
}) => {
  const { scale = defaultScale } = model || {}
  const [mixer] = useState<AnimationMixer>(new AnimationMixer(scene))
  useEffect(() => {
    if (animations.length) {
      animations.forEach((clip) => {
        mixer.clipAction(clip).play()
      })
      return () => {
        mixer.stopAllAction()
      }
    }
    return () => {}
  }, [animations, scene, mixer])

  useEffect(() => {
    // apply flipY = false and encoding = sRGB to all textures
    Object.values(nodes).forEach((node) => {
      const mesh = node as Mesh
      if (mesh.isMesh) {
        const mats = Array.isArray(mesh.material) ? mesh.material : [mesh.material]
        mats.forEach((mat) => {
          const m = mat as MeshStandardMaterial
          const { map } = m
          if (map) {
            map.flipY = false
            map.encoding = sRGBEncoding
          }
        })
      }
    })
  }, [nodes])

  useFrame((_, dT) => {
    mixer.update(dT / 3)
  })
  return (
    <group scale={scale}>
      <primitive object={scene} />
    </group>
  )
}
