// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: 'AIzaSyDhXvc1bGLTkWGG0VgGtClneskLezd5AdM',
  authDomain: 'iso-3d.firebaseapp.com',
  projectId: 'iso-3d',
  storageBucket: 'iso-3d.appspot.com',
  messagingSenderId: '95365730005',
  appId: '1:95365730005:web:bc10f995e2a97e6dda5c5c',
  measurementId: 'G-F7NJZSHQZ7',
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)

export const initialize = () => {
  // eslint-disable-next-line no-console
  console.log('initialized')
}
