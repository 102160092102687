import { Product } from '../types/product'

export const getProductAssetPaths = (product: Product) => ({
  gltf: `/assets/3d/products/${product.type}/model.glb`,
  texture: `/assets/3d/products/${product.type}/${product.id}.jpg`,
  // normal: '/assets/3d/products/shirt/normal.jpg',
  // bump: '/assets/3d/products/shirt/bump.jpg',
  // roughness: '/assets/3d/products/shirt/roughness.jpg',
  sprite: `/assets/images/products/${product.type}/${product.id}/main.png`,
})
