import { useFrame } from '@react-three/fiber'
import { ReactNode, useRef } from 'react'
import { Group, Vector3 } from 'three'

export const FloatingContainer = ({ children }: { children: ReactNode }) => {
  const ref = useRef<Group>(null)

  const rotationSeed = useRef(
    new Vector3(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2),
  )

  const positionSeed = useRef(
    new Vector3(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, 0),
  )

  useFrame(({ clock }) => {
    if (ref.current) {
      ref.current.rotation.x = 0.06 * Math.sin(rotationSeed.current.x + clock.elapsedTime)
      ref.current.rotation.y = 0.085 * Math.cos(rotationSeed.current.y + clock.elapsedTime)
      ref.current.rotation.z = 0.01 * Math.sin(rotationSeed.current.z + clock.elapsedTime)

      ref.current.position.x = 0.1 * Math.cos(positionSeed.current.x + clock.elapsedTime / 5)
      ref.current.position.y = 0.1 * Math.sin(positionSeed.current.y + clock.elapsedTime / 7)
    }
  })

  return (
    <group>
      <group ref={ref} position={[0, 0, -0.05]}>
        {children}
      </group>
    </group>
  )
}
