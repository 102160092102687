export const getElementId = (productId: string, variantId: string | null) => `product-component-${productId}-${variantId || 'root'}`

export async function ShopifyBuyInit(
  ui: any,
  productId: string,
  variantId: string | null,
  node: HTMLElement,
) {
  // remove element children
  const element = document.getElementById(`product-component-${productId}-${variantId || 'root'}`)
  if (element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild)
    }
  }

  ui.createComponent('product', {
    id: productId,
    variantId: variantId || undefined,
    node,
    moneyFormat: '%24%7B%7Bamount%7D%7D',
    options: {
      product: {
        styles: {
          product: {
            '@media (min-width: 601px)': {
              'max-width': 'calc(25% - 20px)',
              'margin-left': '20px',
              'margin-bottom': '50px',
            },
          },
          button: {
            'font-weight': 'bold',
            ':hover': {
              'background-color': '#111',
            },
            'background-color': '#111',
            ':focus': {
              'background-color': '#222',
            },
          },
        },
        contents: {
          img: false,
          title: false,
          price: false,
          options: false,
        },
        text: {
          button: 'Add to cart',
        },
      },
      productSet: {
        styles: {
          products: {
            '@media (min-width: 601px)': {
              'margin-left': '-20px',
            },
          },
        },
      },
      modalProduct: {
        contents: {
          img: false,
          imgWithCarousel: true,
          button: false,
          buttonWithQuantity: true,
        },
        styles: {
          product: {
            '@media (min-width: 601px)': {
              'max-width': '100%',
              'margin-left': '0px',
              'margin-bottom': '0px',
            },
          },
          button: {
            'font-weight': 'bold',
            ':hover': {
              'background-color': '#000000',
            },
            'background-color': '#000000',
            ':focus': {
              'background-color': '#000000',
            },
          },
        },
        text: {
          button: 'Add to cart',
        },
      },
      option: {},
      cart: {
        styles: {
          button: {
            'font-weight': 'bold',
            ':hover': {
              'background-color': '#000000',
            },
            'background-color': '#000000',
            ':focus': {
              'background-color': '#000000',
            },
          },
        },
        text: {
          total: 'Subtotal',
          button: 'Checkout',
        },
      },
      toggle: {
        styles: {
          toggle: {
            'font-weight': 'bold',
            'background-color': '#000000',
            ':hover': {
              'background-color': '#000000',
            },
            ':focus': {
              'background-color': '#000000',
            },
          },
        },
      },
    },
  })
}
