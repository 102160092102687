import { Product } from '@iso-3d/shared'
import {
  RefObject, useEffect, useMemo, useRef, useState,
} from 'react'
import { useShopify } from '../../../store/shopify'
import { ShopifyBuyInit } from './utils'

export const useShopifyBuyButton = (
  productId: string,
  variantId: string | null,
  selected: boolean,
  divRef: RefObject<HTMLDivElement>,
) => {
  const mountedRef = useRef(false)
  const ui = useShopify((s) => s.ui)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const init = async () => {
      if (!divRef.current || !selected) return
      // @ts-ignore
      mountedRef.current = true
      const currChild = divRef.current.children[0]
      setLoading(true)
      await ShopifyBuyInit(ui, productId, variantId, divRef.current)
      if (currChild) {
        divRef.current.removeChild(currChild)
      }
      setLoading(false)
    }
    setTimeout(() => {
      init()
    }, 120)
  }, [productId, variantId, ui, divRef, selected])
  return loading
}

const getImageUrl = ({ type, id }: Product, index: number) => `/assets/images/products/${type}/${id}/${index + 1}.webp`
// const getBlankBackUrl = (blankBackColor: 'white' | 'grey' | 'black') => `/assets/images/products/shirt/shirt-blank-back-${blankBackColor}.jpg`
export const useProductUrls = (product: Product | null) => useMemo(() => {
  if (!product) return []
  if (!product.numImages) return []
  // return Array.from({ length: product.numImages }, (_, i) => (i === 1 && product.blankBackColor ? getBlankBackUrl(product.blankBackColor) : getImageUrl(product, i)))
  return Array.from({ length: product.numImages }, (_, i) => getImageUrl(product, i))
}, [product])
