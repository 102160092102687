import {
  Button, Center, Flex, HStack, IconButton, Image, Link, Text, VStack,
} from '@chakra-ui/react'
import { useApp, useLoading } from 'store'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { ProductDescription } from './ProductDescription'
import { ProductGallery } from './ProductGallery'

type MenuLink = {
  // to: string
  id: string
  href: string
  offsetY?: number
}
const menuLinks: Array<MenuLink> = [
  { href: 'https://news.isosupremacy.com/releases', id: 'releases' },
  { href: 'https://news.isosupremacy.com/about', id: 'about' },
  { href: 'https://www.instagram.com/isosupremacy/', id: 'insta' },
  { href: 'https://news.isosupremacy.com/press', id: 'press', offsetY: 6 },
]

// const MenuLink = ({ children }: { to: string; children: ReactNode }) => (
//   <Text
//     fontSize='3xl'
//     color='white'
//     cursor='pointer'
//     fontFamily='DM Mono'
//     _hover={{
//       color: 'gray.400',
//     }}
//   >
//     {children}
//   </Text>
// )

const MenuLinkView = ({ link: { id, href, offsetY } }: { link: MenuLink }) => (
  <a href={href} target='_blank'>
    <Image
      filter='invert(100%)'
      transform={`translate(0,${offsetY || 0}px)`}
      src={`/assets/images/menu/${id}.png`}
      h='40px'
    />
  </a>
)

const Links = () => (
  <VStack pl={6} flex={1} justify='center' spacing={3} align='flex-start'>
    {menuLinks.map((link) => (
      <MenuLinkView key={link.id} link={link} />
    ))}
  </VStack>
)

const MenuFooter = () => (
  <VStack py={4} w='100%' spacing={1} align='flex-start'>
    {/* <Text fontSize='xl' pl={4} color='#cdcdcd' fontFamily='DM Mono'>
      stay looped
    </Text>
    <Text w='100%' bg='blackAlpha.500' py={2} fontSize='md' pl={4} color='#efefef' fontFamily='DM Mono'>
      email
    </Text>
    <Text w='100%' bg='blackAlpha.500' py={2} fontSize='md' pl={4} color='#efefef' fontFamily='DM Mono'>
      phone
    </Text> */}
    <HStack w='100%' justify='flex-end' py={4} px={6}>
      <a target='_blank' href='https://www.instagram.com/isosupremacy/'>
        <IconButton
          variant='unstyled'
          borderRadius='full'
          width={12}
          height={12}
          boxShadow='inset 1px 1px 3px #00000077'
          padding={0}
          alignItems='center'
          display='flex'
          justifyContent='center'
          _hover={{
            bg: 'whiteAlpha.500',
          }}
          bg='transparent'
          aria-label='IG'
          icon={<Image opacity={0.7} w='28px' src='/assets/images/Instagram_Glyph_White.svg' />}
        />
      </a>
    </HStack>
  </VStack>
)

const IsoLogo = () => <Image position='absolute' top={6} left={6} src={'/assets/images/iso-logo-white.png'} />

const MenuToggleButton = () => {
  const isOpen = useApp((s) => s.menuOpen)
  const loaded = useLoading((s) => s.loaded)

  return (
    <IconButton
      position='absolute'
      top={4}
      w='auto'
      h='auto'
      opacity={loaded ? 1 : 0}
      pointerEvents={loaded ? 'auto' : 'none'}
      display='flex'
      alignItems='center'
      userSelect='none'
      justifyContent='center'
      right={4}
      onClick={() => useApp.setState({ menuOpen: !isOpen })}
      aria-label={isOpen ? 'close menu' : 'open menu'}
      variant='unstyled'
      icon={<Image w='48px' src={'/assets/images/menu_icon.png'} />}
    />
  )
}

const Menu = () => {
  const isOpen = useApp((s) => s.menuOpen)
  return (
    <>
      <Center
        pos='absolute'
        top={0}
        left={0}
        w='100%'
        alignItems='flex-start'
        h='100%'
        flexFlow='column'
        bg='blackAlpha.800'
        pointerEvents={isOpen ? 'auto' : 'none'}
        opacity={isOpen ? 1 : 0}
        transition='opacity 500ms ease'
      >
        <Links />
        <MenuFooter />
      </Center>
      <MenuToggleButton />
    </>
  )
}

const GpuFallbackMessage = () => (
  <Flex
    align='flex-end'
    justify='center'
    position='absolute'
    top={0}
    left={0}
    p={5}
    pointerEvents='none'
    w='100%'
    h='100%'
    color='white'
    fontFamily='DM Mono'
  >
    <Center pointerEvents='auto' maxW='100%' p={4} borderRadius={6} bg='blackAlpha.500' flexFlow='column'>
      <Text>GPU Required</Text>
      <Text fontSize='md'>This site has 3D features and may be laggy on your browser. </Text>
      <Link
        mt={2}
        textDecor='underline'
        maxW='100%'
        fontSize='md'
        href='https://shop.isosupremacy.com'
      >
        Click here to shop on the 2D site
      </Link>
    </Center>
  </Flex>
)

const CookiesBanner = () => {
  const { value: acceptedCookies, updateValue } = useLocalStorage<boolean>('iso-accepted-cookies')

  if (acceptedCookies) return null

  return (
    <Flex
      align='flex-end'
      justify='center'
      position='absolute'
      top={0}
      left={0}
      p={5}
      pointerEvents='none'
      w='100%'
      h='100%'
      color='white'
    >
      <Center gap={2} w='95%' maxW='400px' bg='blackAlpha.600' border='1px solid #ffffff88' borderRadius={6} p={4} flexFlow='column'>
        <Text textAlign='center' fontSize='sm'>
          This site uses cookies to enhance your experience. By continuing to use this site, you agree to our use of
          cookies.
        </Text>
        <Button
          pointerEvents='auto'
          ml={4}
          variant='solid'
          bg='blue.400'
          _hover={{
            bg: 'blue.500',
          }}
          size='md'
          fontSize='lg'
          textShadow='1px 1px 3px #00000077'
          aria-label='Accept Cookies'
          onClick={() => updateValue(true)}
        >
          Accept Cookies
        </Button>
      </Center>
    </Flex>
  )
}

export const Flat = ({ gpuTier }: { gpuTier: number }) => (
  <>
    {/* <ProductFrame /> */}
    <ProductGallery />
    <ProductDescription />
    <Menu />
    <IsoLogo />
    {gpuTier ? null : <GpuFallbackMessage />}
    <CookiesBanner />
  </>
)
