import {
  closeButtonGlbPath, galleryGlbPath,
  shoppingBagGlbPath,
} from '@iso-3d/shared'
import { animated as a, useSpring } from '@react-spring/three'
import { useGLTF } from '@react-three/drei'
import { useMemo } from 'react'
import { Mesh, MeshPhongMaterial, Vector3Tuple } from 'three'
import { GLTF } from 'three-stdlib'
import {
  deselectProduct,
  useDescription, useGallery,
  useSelectedProduct,
} from '../../../../../../store/products'
import { ManualClickableSphere } from '../../../../../shared/ClickableSphere'

type ProductViewButton = {
  color: number
  glb: {
    path: string
    position?: [number, number, number]
    rotation?: [number, number, number]
    scale?: [number, number, number]
  }
}

const getButtonPosition = (index: number, numButtons: number): Vector3Tuple => {
  const angle = Math.PI / 4
  const radius = 1.5
  const x = radius * Math.cos(-Math.PI / 1.8 + angle * (index / numButtons))
  const y = radius * Math.sin(-Math.PI / 1.8 + angle * (index / numButtons))
  return [x, y + 1.5, 0]
}

const closeButton: ProductViewButton = {
  color: 0xcc3333,
  glb: { path: closeButtonGlbPath, scale: [0.075, 0.075, 0.075], rotation: [0, Math.PI / 2, 0] },
}

// const infoButton: ProductViewButton = {
//   color: 0x888888,
//   glb: { path: infoGlbPath, scale: [0.22, 0.22, 0.22], rotation: [0, 0, 0] },
// }

const galleryButton: ProductViewButton = {
  color: 0x888888,
  glb: {
    path: galleryGlbPath,
    scale: [0.22, 0.22, 0.22],
    position: [0, 0, 0],
    rotation: [0, Math.PI, 0],
  },
}

const shoppingBagButton: ProductViewButton = {
  color: 0x888888,
  glb: { path: shoppingBagGlbPath, scale: [0.22, 0.22, 0.22], rotation: [0, 0, 0] },
}

const meshIconMaterial = new MeshPhongMaterial({ color: 0xefefef })

const getButtonRotation = (index: number): Vector3Tuple => [Math.PI / 6.5, (index - 0.5) * (Math.PI / 36), 0]

const ProductViewButtonView = ({
  button,
  active,
  onClick,
  numButtons,
  index,
}: {
  button: ProductViewButton
  onClick: () => void
  active: boolean
  numButtons: number
  index: number
}) => {
  const { nodes } = useGLTF(button.glb.path) as unknown as GLTF & { nodes: Record<string, Mesh> }
  const meshes = useMemo(() => Object.values(nodes).filter((n) => n.isMesh), [nodes])
  const positionObj = useMemo(() => getButtonPosition(index, numButtons), [index, numButtons])
  const { position } = useSpring({
    position: positionObj,
    config: { mass: 1, tension: 100, friction: 10 },
    delay: active ? 0 : 500,
  })
  const { scale } = useSpring({
    scale: active ? 1 : 0,
    config: { mass: 1, tension: 100, friction: 10 },
    delay: active ? 500 + 100 * index : 0,
  })
  const rotation = useMemo(() => getButtonRotation(index), [index])
  return (
    <a.group
      position={position}
      rotation={rotation}
      scale={scale.to((s) => [s, s, s])}
    >
      <ManualClickableSphere scale={[0.17, 0.17, 0.17]} position={[0, 0, 0.14]} onClick={onClick} />
      <mesh position={[0, 0, 0.14]}>
        <sphereGeometry args={[0.15]} />
        <meshPhongMaterial shininess={0.1} reflectivity={0.02} color={button.color} />
      </mesh>
      <group position={button.glb.position} scale={button.glb.scale} rotation={button.glb.rotation}>
        {meshes.map((mesh) => (
          <mesh
            material={meshIconMaterial}
            position={mesh.position}
            rotation={mesh.rotation}
            scale={mesh.scale}
            geometry={mesh.geometry}
            key={mesh.uuid}
          />
        ))}
      </group>
    </a.group>
  )
}

export const ProductViewButtons = () => {
  const selectedProduct = useSelectedProduct()

  const showGalleryButton = useMemo(() => (!selectedProduct || selectedProduct.numImages > 0), [selectedProduct])

  const indices = useMemo(() => (showGalleryButton ? [0, 1, 2, 3] : [0, 1, 1, 2]), [showGalleryButton])
  // const numButtons = useMemo(() => (showGalleryButton ? 4 : 3), [showGalleryButton])

  return (
    <group position={[0, -1.55, -2]}>
      <ProductViewButtonView
        // onClick={() => {
        //   if (!selectedProduct) return
        //   window.open(selectedProduct.shopUrl, '_blank')
        // }}
        onClick={() => useDescription.setState({ isOpen: true })}
        active={!!selectedProduct}
        button={shoppingBagButton}
        numButtons={3}
        index={indices[0]}
      />
      <ProductViewButtonView
        onClick={() => useGallery.setState({ isOpen: true })}
        active={!!selectedProduct && !!showGalleryButton}
        button={galleryButton}
        numButtons={3}
        index={indices[1]}
      />
      {/* <ProductViewButtonView
        onClick={() => useDescription.setState({ isOpen: true })}
        active={!!selectedProduct}
        button={infoButton}
        numButtons={4}
        index={indices[2]}
      /> */}
      <ProductViewButtonView
        onClick={() => deselectProduct()}
        active={!!selectedProduct}
        button={closeButton}
        numButtons={3}
        index={indices[2]}
      />
    </group>
  )
}
