import { useSpring } from '@react-spring/three'
import { Plane } from '@react-three/drei'
import {
  PlaneBufferGeometryProps, extend, useFrame, useThree,
} from '@react-three/fiber'
import { useEffect, useMemo, useRef } from 'react'
import {
  BackSide, Mesh, ShaderMaterial,
  Vector3Tuple,
} from 'three'
import { useProducts } from '../../../../../store/products'
import { NoiseMaterial1 } from './noiseMaterial1'

extend({ NoiseMaterial1 })

const initColor: Vector3Tuple = [0.15, 0.15, 0.17]
const selectedColor: Vector3Tuple = [0.45, 0.45, 0.5]

export const Background = () => {
  const materialRef1 = useRef<ShaderMaterial>(null)
  // const materialRef2 = useRef<ShaderMaterial>(null)
  const s1 = useRef<Mesh>(null)
  const selectedProductId = useProducts((state) => state.selectedProductId)

  const { width, height } = useThree((state) => state.viewport)
  useFrame((_, delta) => {
    const matRefs = [materialRef1]
    matRefs.forEach((m) => {
      const mat = m.current
      if (!mat) return
      mat.uniforms.time.value += delta * 0.7
    })
  })

  useSpring<{color: Vector3Tuple}>({
    color: selectedProductId ? selectedColor : initColor,
    onChange: ({ value }) => {
      const matRefs = [materialRef1]
      const [r, g, b] = value.color
      matRefs.forEach((m) => {
        const mat = m.current
        if (!mat) return
        mat.uniforms.color.value.x = r
        mat.uniforms.color.value.y = g
        mat.uniforms.color.value.z = b
      })
    },
  })

  useEffect(() => {
    const onWindowResize = () => {
      const matRefs = [materialRef1]
      matRefs.forEach((m) => {
        const mat = m.current
        if (!mat) return
        mat.uniforms.resolution.value.x = window.innerWidth
        mat.uniforms.resolution.value.y = window.innerHeight
      })
    }
    onWindowResize()
    window.addEventListener('resize', onWindowResize)
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])
  const args = useMemo<PlaneBufferGeometryProps['args']>(() => {
    const size = Math.max(width * 1.2, height * 1.2)
    return [size, size]
  }, [width, height])
  return (
    <group>
      <Plane args={args} position={[0, 0, 0]} rotation={[0, 0, 0]} ref={s1}>
        {/* @ts-ignore */}
        <noiseMaterial1 transparent side={BackSide} color={initColor} ref={materialRef1} />
      </Plane>
      {/* <Sky /> */}
    </group>
  )
}
