import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        color: 'black',

        fontFamily: 'DM Mono',
      },
      variants: {
        solid: {
          color: 'white',
          fontSize: '1.4rem',
          background: 'transparent',
          _focus: {
            background: 'transparent',
          },
          _hover: {
            background: 'transparent',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'DM Mono',
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'white',
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'DM Mono',
        whiteSpace: 'pre-wrap',
        fontSize: '1.3rem',
        fontWeight: '500',
      },
    },
  },
})
