import { useCallback, useEffect, useState } from 'react'

export const getLocalStorage = (key: string) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : null
}

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const useLocalStorage = <T extends {}>(key: string | undefined) => {
  // get the value from local storage
  const [value, setValue] = useState<T | null>(key ? getLocalStorage(key) : null)

  // subscribe to changes in local storage
  useEffect(() => {
    if (!key) return () => {}
    const handler = () => {
      setValue(getLocalStorage(key))
    }
    window.addEventListener('storage', handler)
    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [key])

  const updateValue = useCallback((newValue: T) => {
    if (key) {
      setValue(newValue)
      setLocalStorage(key, newValue)
    }
  }, [key])

  return { value, updateValue }
}

export const useHBDeviceId = () => useLocalStorage('hb_device_id')
