import { Sphere } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useCallback, useRef } from 'react'
import {
  Box3, Group, Mesh, Vector3Tuple,
} from 'three'

interface ClickableSphereProps {
  onClick?: () => void
  onHover?: () => void
  onBlur?: () => void
  debug?: boolean
  children: React.ReactNode
}

export const ClickableSphere: React.FC<ClickableSphereProps> = ({
  onClick, children, onBlur, onHover, debug,
}) => {
  const sphereRef = useRef<Mesh>(null)
  const canvasEl = useThree(({ gl }) => gl.domElement)
  const groupRef = useRef<Group>(null)
  // Register the sphere as a clickable object
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])
  const handleHover = useCallback(() => {
    if (onHover) onHover()
    canvasEl.style.cursor = 'pointer'
  }, [canvasEl, onHover])
  const handleBlur = useCallback(() => {
    if (onBlur) onBlur()
    canvasEl.style.cursor = 'default'
  }, [canvasEl, onBlur])
  const helperBox = useRef<Box3>(new Box3())
  // Update the sphere's position and size to match the children
  useFrame(() => {
    if (!sphereRef.current || !groupRef.current) return
    helperBox.current.setFromObject(groupRef.current)
    helperBox.current.getCenter(sphereRef.current.position)
    helperBox.current.getSize(sphereRef.current.scale)
    sphereRef.current.scale.multiplyScalar(0.7)
  })

  return (
    <>
      <group ref={groupRef}>
        {children}
        <Sphere
          onClick={handleClick}
          onPointerOver={handleHover}
          onPointerOut={handleBlur}
          args={[1, 16, 16]}
          ref={sphereRef}
        >
          <meshStandardMaterial depthWrite={false} opacity={debug ? 0.5 : 0} transparent />
        </Sphere>
      </group>
    </>
  )
}

export const ManualClickableSphere = ({
  onClick,
  position,
  onBlur,
  onHover,
  debug,
  rotation,
  scale,
}: Omit<ClickableSphereProps, 'children'> & {
  position?: Vector3Tuple
  rotation?: Vector3Tuple
  scale?: Vector3Tuple
}) => {
  const { domElement } = useThree((state) => state.gl)

  const handleHover = useCallback(() => {
    if (onHover) onHover()
    domElement.style.cursor = 'pointer'
  }, [domElement, onHover])
  const handleBlur = useCallback(() => {
    if (onBlur) onBlur()
    domElement.style.cursor = 'default'
  }, [domElement, onBlur])

  return (
    <Sphere
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) onClick()
      }}
      onPointerOver={handleHover}
      onPointerOut={handleBlur}
      args={[1, 16, 16]}
      position={position}
      rotation={rotation}
      scale={scale}
    >
      <meshStandardMaterial depthWrite={false} opacity={debug ? 0.5 : 0} transparent />
    </Sphere>
  )
}
