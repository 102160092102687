import {
  Button, Center, Collapse, Flex, IconButton, Image, Text,
} from '@chakra-ui/react'
import { CSSProperties, useEffect, useMemo } from 'react'
import {
  useDescription, useGallery, useProducts, useSelectedProduct,
} from '../../../store/products'
import { useShopify } from '../../../store/shopify'
import { BuyButton } from './BuyButton'
import { useProductUrls } from './hooks'
import './styles.css'

const xSrc = '/assets/images/x.png'

export const ProductDescription = () => {
  const isOpen = useDescription((s) => s.isOpen)
  const selectedProduct = useSelectedProduct()
  const selectedVariantId = useProducts((s) => s.selectedVariantId)
  const shopifyLoaded = useShopify((s) => s.loaded)

  useEffect(
    () => useProducts.subscribe(({ selectedProductId }) => {
      if (!selectedProductId) {
        useDescription.setState({ isOpen: false })
      }
    }),
    [],
  )

  const hasVariants = useMemo(() => (selectedProduct?.variants?.length || 0) > 1, [selectedProduct])

  const style = useMemo<CSSProperties>(
    () => (isOpen ? { opacity: 1, pointerEvents: 'auto' } : { opacity: 0, pointerEvents: 'none' }),
    [isOpen],
  )

  const productUrls = useProductUrls(selectedProduct)

  return (
    <div style={style} className='overlay'>
      <Center
        opacity={isOpen ? 1 : 0}
        transition={`opacity 300ms ease ${isOpen ? 300 : 0}ms`}
        maxW='400px'
        w='90%'
        h='80%'
        flexFlow='column'
      >
        <Flex w='100%'>
          {/* <Button
          onClick={() => {
            if (!selectedProduct) return
            window.open(selectedProduct.shopUrl, '_blank')
          }}
          _hover={{ bg: '#99aa9977' }} ml='auto' bg='#77997777' fontFamily='DM Mono'>
            <Text>BUY</Text>
          </Button> */}
        </Flex>
        <Flex
          py={3}
          px={5}
          overflowX='auto'
          border='1px solid #111'
          borderRadius={4}
          bg='#222224'
          flexFlow='column'
          align='center'
          w='100%'
        >
          <Flex flexFlow='column' gap={3} w='100%'>
            <Flex w='100%' py={1}>
              <Flex w='100%' justify='center' gap={2} h='auto'>
                {productUrls.map((url, i) => (
                  <IconButton
                    key={url}
                    variant='unstyled'
                    borderRadius={6}
                    overflow='hidden'
                    p={0}
                    minW='0'
                    h='auto'
                    w='auto'
                    position='relative'
                    aria-label={`image ${i + 1}`}
                    icon={<Image src={url} h='48px' objectFit='contain' />}
                    onClick={() => {
                      useDescription.setState({ isOpen: false })
                      useGallery.setState({ isOpen: true, selectedImageIndex: i })
                    }}
                  />
                ))}
                <IconButton
                  ml='auto'
                  aria-label='close'
                  onClick={() => useDescription.setState({ isOpen: false })}
                  icon={
                    <Flex gap={1} align='center'>
                      <Image position='relative' top='2px' filter='invert(100%)' opacity={0.7} src={xSrc} h={6} />
                    </Flex>
                  }
                />
              </Flex>
            </Flex>
            <Flex align='center' justify='center' w='100%'>
              <Text color='white' textShadow='1px 1px 3px #00000077' fontFamily='DM Mono'>
                {selectedProduct?.description}
              </Text>
            </Flex>
          </Flex>
          {hasVariants ? (
            <Flex pt={2} w='100%' justify='center' gap={2}>
              {selectedProduct?.variants?.map((v) => (
                <Button
                  key={v.shopifyId}
                  bg={selectedVariantId === v.shopifyId ? '#060606' : '#transparent'}
                  color={selectedVariantId === v.shopifyId ? '#fff' : '#aaa'}
                  _hover={{
                    bg: selectedVariantId === v.shopifyId ? '#111' : '#161616',
                    color: selectedVariantId === v.shopifyId ? '#fff' : '#aaa',
                  }}
                  _focus={{
                    bg: selectedVariantId === v.shopifyId ? '#111' : '#161616',
                    color: selectedVariantId === v.shopifyId ? '#fff' : '#aaa',
                  }}
                  fontSize='sm'
                  w={10}
                  h={10}
                  borderRadius='full'
                  p={2}
                  cursor='pointer'
                  onClick={() => useProducts.setState({ selectedVariantId: v.shopifyId })}
                >
                  {v.name}
                </Button>
              ))}
            </Flex>
          ) : null}
          <Collapse style={{ width: '100%' }} in={!shopifyLoaded}>
            <Flex w='100%' justify='center'>
              <Text color='white' fontSize='sm' fontFamily='DM Mono'>Loading...</Text>
            </Flex>
          </Collapse>
          <Collapse style={{ width: '100%' }} in={isOpen && shopifyLoaded && !!selectedProduct}>
          {isOpen && selectedProduct && shopifyLoaded ? (
            <Center
              w='100%'
              minH='70px'
              opacity={hasVariants && !selectedVariantId ? 0.5 : 1}
              pointerEvents={hasVariants && !selectedVariantId ? 'none' : 'auto'}
            >
              {selectedProduct.variants ? (
                <Flex flexFlow='column' w='100%' align='center'>
                  {selectedProduct.variants.map((v) => (
                    <BuyButton
                      key={v.shopifyId}
                      selected={selectedVariantId === v.shopifyId}
                      variantId={v.shopifyId}
                      product={selectedProduct}
                    />
                  ))}
                </Flex>
              ) : (
                <BuyButton selected variantId={null} product={selectedProduct} />
              )}
            </Center>
          ) : null}
          </Collapse>
        </Flex>
      </Center>
    </div>
  )
}
