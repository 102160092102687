import { Vector3Tuple } from 'three'

export const getPositionAndRotationAroundCircle = (
  angle: number,
  radius: number,
  y = 0.05,
  offset = [0, 0, 0],
): { position: Vector3Tuple; rotation: Vector3Tuple } => {
  const [ox,, oz] = offset
  const x = Math.sin(angle) * radius
  const z = Math.cos(angle) * radius
  const rotation = angle
  return { position: [x + ox, y, z + oz], rotation: [0, rotation, 0] }
}

export const galleryStepPositions = [9, 11, 13, 15, 17, 19, 21, 23, 25].map(
  (step) => getPositionAndRotationAroundCircle(-Math.PI / 9.5, step).position,
)

export const mainFloorPosition: Vector3Tuple = [0, 0, 0]
export const mainRoofPosition: Vector3Tuple = [0, 4.1, 0]

export const toCameraPosition = ([x, y, z]: Vector3Tuple): Vector3Tuple => [
  x,
  y + 1,
  z,
]
export const toLookAtPosition = ([x, y, z]: Vector3Tuple): Vector3Tuple => [
  x,
  y + 1,
  z + 0.01,
]

export const viewPositions: Record<
  string,
  { position: Vector3Tuple; lookAt: Vector3Tuple }
> = {
  demo: {
    position: [0, 0, 0],
    lookAt: [0, 0.1, 0],
  },
}

export const getInitViewPosition = (view: string): Vector3Tuple => {
  const { position: [x, y, z] } = viewPositions[view]
  return [x, y, z - 5.6]
}
