import { DemoView } from '../types/views'

export const demoView: DemoView = {
  id: 'demo',
  name: 'Demo',
  path: '/',
}

// export const gachaponTestView: Gacg = {
//   id: 'gachaponTest',
//   name: 'Gachapon Test',
//   path: '/test',
// }

export const sceneViews = [
  demoView,
]
