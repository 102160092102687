import { FileValue, WithId } from '../types'
import {
  Field,
  FieldMap,
  FieldType,
  isField,
  isRecordField,
  SelectField,
} from '../types/forms'

const isRecord = (data?: any) => typeof data === 'object' && !(data instanceof Array) && data !== null
export const processDataForRead = (field: FieldMap, data?: any) => {
  const acc: Record<string, any> = {
    ...(isRecord(data) ? data : {}),
  }
  const entries = Object.entries(field.children)
  const numChildren = entries.length
  for (let i = 0; i < numChildren; i += 1) {
    const [fieldKey, child] = entries[i]
    const childValue = data?.[fieldKey]
    if (!isField(child)) {
      if (isRecordField(child)) {
        const items = Object.entries(childValue || {})
        const { itemField } = child
        if (isField(itemField)) acc[fieldKey] = recordToArray(childValue)
        else {
          acc[fieldKey] = items.map(([key, itemData]) => ({
            _id: key,
            ...processDataForRead(itemField, itemData),
          }))
        }
      } else {
        acc[fieldKey] = processDataForRead(child, childValue)
      }
    }
  }
  return acc
}

// replaces undefined values with empty string/object/array for updateDoc calls
export const processDataForWrite = (field: FieldMap, data?: any) => {
  const acc: Record<string, any> = {
    ...(typeof data === 'object' && data !== null ? data : {}),
  }
  const entries = Object.entries(field.children)
  const numChildren = entries.length
  for (let i = 0; i < numChildren; i += 1) {
    const [fieldKey, child] = entries[i]
    const childValue = data?.[fieldKey]
    const asNum = Number.parseFloat(data?.[fieldKey] || '')
    if (isField(child)) {
      switch (child._type) {
        case 'file':
          acc[fieldKey] = childValue || {}
          delete acc[fieldKey].processImageResults
          break
        case 'boolean':
          acc[fieldKey] = childValue || false
          break
        case 'currency':
        case 'number':
          if (Number.isNaN(asNum)) acc[fieldKey] = undefined
          else acc[fieldKey] = asNum
          break
        default:
          acc[fieldKey] = childValue || ''
          break
      }
    } else if (isRecordField(child)) {
      const { itemField } = child
      if (isField(itemField)) acc[fieldKey] = arrayToRecord(childValue)
      else {
        acc[fieldKey] = childValue.reduce(
          (childAcc, itemData) => ({
            ...childAcc,
            [itemData._id]: processDataForWrite(itemField, itemData),
          }),
          {} as Record<string, any>,
        )
      }
    } else {
      acc[fieldKey] = processDataForWrite(child, data?.[fieldKey])
    }
  }
  return acc
}

export const recordToArray = (record?: Record<string, any>) => Object.entries(record || {}).map(([_id, val]) => ({ ...val, _id }))

export const arrayToRecord = (arr?: Array<WithId<Record<string, any>>>) => (arr || []).reduce(
  (acc, curr) => ({
    ...acc,
    [curr._id]: curr,
  }),
    {} as Record<string, any>,
)

const dateHelper = new Date()

export const formatDateTime = (val?: number) => {
  if (!val) return ''
  dateHelper.setTime(val)
  return dateHelper.toLocaleString()
}

export const formatDate = (val?: number) => {
  if (!val) return ''
  dateHelper.setTime(val)
  return dateHelper.toLocaleDateString()
}

export const fieldFormats: Record<
  FieldType,
  (val: any, field: Field) => string
> = {
  text: (val) => val || '',
  boolean: (val) => (val ? 'Yes' : 'No'),
  currency: (val: number) => `$${val.toFixed(2)}`,
  date: (val: number) => {
    if (!val) return ''
    dateHelper.setTime(val)
    return dateHelper.toLocaleDateString()
  },
  file: (val?: FileValue) => val?.title || '',
  number: (val: number) => (val === undefined ? '' : `${val}`),
  select: (val, field: Field) => (val
    ? (field as SelectField).options.find((o) => o.id === val)?.text
        || 'option not found'
    : ''),
  textarea: (val) => val,
  time: (val) => val,
}

export const format = (field: Field, val: any) => fieldFormats[field._type](val, field)

export const getItemName = (label?: string) => {
  if (!label) return ''
  if (label[label.length - 1].toLowerCase() === 's') return label.slice(0, label.length - 1)
  return label
}
