import { Product } from '../types/product'

export const shirtRed: Product = {
  type: 'shirt',
  id: 'shirt-red',
  name: 'Red Logo Shirt',
  shopUrl: 'https://shop.isosupremacy.com/products/2-tone-logo-t-shirt-black',
  description:
    '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 2,
  shopifyId: '8243114508486',
  blankBackColor: 'black',
  variants: [
    {
      name: 'S',
      shopifyId: '43891985481926',
    },
    {
      name: 'M',
      shopifyId: '43891985514694',
    },
    {
      name: 'L',
      shopifyId: '43891985547462',
    },
    {
      name: 'XL',
      shopifyId: '43891985580230',
    },
    {
      name: 'XXL',
      shopifyId: '43891985612998',
    },
  ],
}

export const shirtYellow: Product = {
  type: 'shirt',
  id: 'shirt-yellow',
  name: 'Yellow Logo Shirt',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-two-tone-tee',
  description:
    '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 2,
  shopifyId: '8227317711046',
  blankBackColor: 'white',
  variants: [
    {
      name: 'S',
      shopifyId: '43846148915398',
    },
    {
      name: 'M',
      shopifyId: '43846148948166',
    },
    {
      name: 'L',
      shopifyId: '43846148980934',
    },
    {
      name: 'XL',
      shopifyId: '43846149013702',
    },
    {
      name: 'XXL',
      shopifyId: '43846149046470',
    },
  ],
}

// export const shirtBlue: Product = {
//   type: 'shirt',
//   id: 'shirt-blue',
//   name: 'Blue Logo Shirt',
//   description: 'A grey shirt with a blue logo.  It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
// }

export const shirtWhite: Product = {
  type: 'shirt',
  id: 'shirt-white',
  name: 'White Logo Shirt',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-spellout-tee',
  description:
    '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 4,
  shopifyId: '8227312763078',
  variants: [
    {
      name: 'S',
      shopifyId: '43891978502342',
    },
    {
      name: 'M',
      shopifyId: '43891978535110',
    },
    {
      name: 'L',
      shopifyId: '43891978567878',
    },
    {
      name: 'XL',
      shopifyId: '43891978600646',
    },
    {
      name: 'XXL',
      shopifyId: '43891978633414',
    },
  ],
}

export const pantsBlack: Product = {
  type: 'pants',
  id: 'pants-black',
  name: 'Black Pants',
  description:
    '100% 14 oz. Heavy Cotton Fleece sweatpants with inside drawcord, two side pockets, and back pocket on right leg. Made In USA.',
  numImages: 4,
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-sweatpant-black',
  shopifyId: '8243114016966',
  variants: [
    {
      name: 'S',
      shopifyId: '43891984171206',
    },
    {
      name: 'M',
      shopifyId: '43891984203974',
    },
    {
      name: 'L',
      shopifyId: '43891984236742',
    },
    {
      name: 'XL',
      shopifyId: '43891984269510',
    },
  ],
}

const chalkTeeBlack: Product = {
  type: 'shirt',
  id: 'chalk-tee-black',
  name: 'Chalk Tee Black',
  description: '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 1,
  blankBackColor: 'black',
  shopifyId: '8280346296518',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-chalk-tee-black',
  variants: [
    {
      name: 'S',
      shopifyId: '43981702824134',
    },
    {
      name: 'M',
      shopifyId: '43981702856902',
    },
    {
      name: 'L',
      shopifyId: '43981702889670',
    },
    {
      name: 'XL',
      shopifyId: '43981702922438',
    },
    {
      name: 'XXL',
      shopifyId: '43981702955206',
    },
  ],
}

const chalkTeeNatural: Product = {
  type: 'shirt',
  id: 'chalk-tee-natural',
  name: 'Chalk Tee Natural',
  description: '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 1,
  shopifyId: '8280348098758',
  blankBackColor: 'white',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-chalk-tee-natural',
  variants: [
    {
      name: 'S',
      shopifyId: '43981706690758',
    },
    {
      name: 'M',
      shopifyId: '43981706723526',
    },
    {
      name: 'L',
      shopifyId: '43981706756294',
    },
    {
      name: 'XL',
      shopifyId: '43981706789062',
    },
    {
      name: 'XXL',
      shopifyId: '43981706821830',
    },
  ],
}

const footprintTeeBlack: Product = {
  type: 'shirt',
  id: 'footprint-tee-black',
  name: 'Footprint Tee Black',
  description: '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 2,
  shopifyId: '8280347017414',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-footprint-tee-black',
  variants: [
    {
      name: 'S',
      shopifyId: '43981704462534',
    },
    {
      name: 'M',
      shopifyId: '43981704495302',
    },
    {
      name: 'L',
      shopifyId: '43981704528070',
    },
    {
      name: 'XL',
      shopifyId: '43981704560838',
    },
    {
      name: 'XXL',
      shopifyId: '43981704593606',
    },
  ],
}

const footprintTeeNatural: Product = {
  type: 'shirt',
  id: 'footprint-tee-natural',
  name: 'Footprint Tee Natural',
  description: '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 2,
  shopifyId: '8280348459206',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-footprint-tee-natural',
  variants: [
    {
      name: 'S',
      shopifyId: '43981707149510',
    },
    {
      name: 'M',
      shopifyId: '43981707182278',
    },
    {
      name: 'L',
      shopifyId: '43981707215046',
    },
    {
      name: 'XL',
      shopifyId: '43981707247814',
    },
    {
      name: 'XXL',
      shopifyId: '43981707280582',
    },
  ],
}

const logoTeeGrey: Product = {
  type: 'shirt',
  id: 'logo-tee-grey',
  name: 'Logo Tee Grey',
  description: '100% 6.5 oz. 100% Cotton Short Sleeve Graphic Tee. Made in USA.',
  numImages: 1,
  blankBackColor: 'grey',
  shopifyId: '8283654914246',
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-two-tone-logo-t-shirt-gray',
  variants: [
    {
      name: 'S',
      shopifyId: '43988814987462',
    },
    {
      name: 'M',
      shopifyId: '43988815020230',
    },
    {
      name: 'L',
      shopifyId: '43988815052998',
    },
    {
      name: 'XL',
      shopifyId: '43988815085766',
    },
    {
      name: 'XXL',
      shopifyId: '43988815118534',
    },
  ],
}

export const album: Product = {
  type: 'album',
  description: 'Brent Faiyaz “Larger Than Life” Vinyl',
  id: 'nuwo',
  name: 'Larger Than Life Vinyl',
  renderScene: true,
  shopUrl: 'https://shop.isosupremacy.com/products/iso-supremacy-sweatpant-black',
  model: {
    scale: [0.5, 0.5, 0.5],
  },
  numImages: 4,
  shopifyId: '8244545159366',
  variants: [
    {
      name: '',
      shopifyId: '43896287822022',
    },
  ],
}

export const products = [
  shirtRed,
  shirtYellow,
  // shirtBlue,
  shirtWhite,
  pantsBlack,
  album,
  chalkTeeBlack,
  chalkTeeNatural,
  footprintTeeBlack,
  footprintTeeNatural,
  logoTeeGrey,
]

export const productFrameGlbPath = '/assets/3d/models/productframe.glb'
export const magnifyingGlassGlbPath = '/assets/3d/models/magnifying-glass.glb'
export const closeButtonGlbPath = '/assets/3d/models/close.glb'
export const galleryGlbPath = '/assets/3d/models/gallery.glb'
export const infoGlbPath = '/assets/3d/models/info.glb'
export const shoppingBagGlbPath = '/assets/3d/models/shopping.glb'
